<template>
  <b-card class="p-2">
    <b-link @click="goBack">
      <feather-icon
        class="position-absolute text-primary"
        size="40"
        icon="ArrowLeftCircleIcon"
      />
    </b-link>
    <b-form ref="form">
      <b-row>
        <b-col
          class="mb-4 d-flex justify-content-center align-items-center"
          cols="12"
        >
          <feather-icon
            icon="MapPinIcon"
            size="25"
            class="mr-2"
          />
          <h1>Nuova Sede</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Nome Sede"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="headquarterData.name"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Numero di Telefono"
            label-for="phone_number"
          >
            <b-form-input
              id="phone_number"
              v-model="headquarterData.phone_number"
            />
          </b-form-group>
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
        >
          <b-form-group label="Indirizzo">
            <map-input
              :edit="true"
              @input="setAddress($event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="text-right mt-2"
          cols="12"
        >
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="addHeadquarter"
          >
            Aggiungi
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="reset"
          >
            Pulisci
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BLink,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import MapInput from '@/views/apps/headquarters/MapInput.vue'
import headquartersStoreModule from '../headquartersStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BLink,
    MapInput,
  },
  setup() {
    const HEADQUARTERS_APP_STORE_MODULE_NAME = 'app-headquarters'

    // Register module
    if (!store.hasModule(HEADQUARTERS_APP_STORE_MODULE_NAME)) {
      store.registerModule(HEADQUARTERS_APP_STORE_MODULE_NAME, headquartersStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HEADQUARTERS_APP_STORE_MODULE_NAME)) { store.unregisterModule(HEADQUARTERS_APP_STORE_MODULE_NAME) }
    })

    return {
      headquarterData: {
        name: null,
        phone_number: null,
      },
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    setAddress(event) {
      if (!event) return
      if (!event.address_components) return
      let street_number = null
      let route = null
      let locality = null
      let municipality = null
      let province = null
      let region = null
      let country = null
      let postal_code = null
      event.address_components.filter(el => {
        if (el.types[0] == 'street_number') street_number = el.short_name
        if (el.types[0] == 'route') route = el.short_name
        if (el.types[0] == 'locality') locality = el.short_name
        if (el.types[0] == 'administrative_area_level_3') municipality = el.short_name
        if (el.types[0] == 'administrative_area_level_2') province = el.short_name
        if (el.types[0] == 'administrative_area_level_1') region = el.short_name
        if (el.types[0] == 'country') country = el.short_name.substring(0, 2).toUpperCase()
        if (el.types[0] == 'postal_code') postal_code = el.short_name
      })
      if (street_number) {
        this.headquarterData.address_street = `${route}, ${street_number}`
      }
      if (!street_number) {
        this.headquarterData.address_street = route
      }
      this.headquarterData.address_cap = postal_code
      this.headquarterData.address_city = locality
      this.headquarterData.address_province = province
      this.headquarterData.address_nation_code = country
      this.headquarterData.address_coordinates = event.coordinates
    },
    addHeadquarter() {
      store
        .dispatch('app-headquarters/addHeadquarter', this.headquarterData)
        .then(result => {
          router.replace({ name: 'apps-headquarters-list' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Sede #${result.data.id} aggiunta con successo`,
                icon: 'MapPinIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
    reset() {
      this.$refs.form.reset()
    },
  },
}
</script>

<style>
.position-absolute {
  z-index: 1;
}
</style>
